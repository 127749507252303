import React from "react"
import Budget from "../../components/body/pages/en-ng/budget"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const BudgetPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/budget/"}
      title="Budget & Save with Kuda | The Money App For Africans"
      description="Financial clarity is everything! Budgeting with Kuda will show you details of your spending so you can make better money decisions. Join Kuda today!"
    />
    <Budget />
  </Layout>
)

export default BudgetPage
