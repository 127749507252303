import React, { useEffect, Fragment } from "react";
import BelowFoldCards from "../../general/belowFoldCards";
import CTA from "../../general/cta";
import EntryFold from "../../general/entryFold";
import Card from "../../../../../assets/img/dive-budget-icon.inline.svg";
import Money from "../../../../../assets/img/see-money.inline.svg";
import BudgetIcon from "../../../../../assets/img/budget-icon.inline.svg";
import BudgetHeadingFallback from "../../../../../assets/img/blur/kuda-new-budget-blur.inline.svg";
import { scrollToElement } from "../../../../utility/utils";
import ImageTextLeft from "../../general/imageTextLeft";
import ImageTextRight from "../../general/imageTextRight";
// import BudgetIllustration from "../../../../../assets/img/kuda-new-budget.png";
import SpendSaveIllustration from "../../../../../assets/img/spendSaveIllustration.inline.svg";
import SetBudgetIllustration from "../../../../../assets/img/setBudget.inline.svg";
import SetCategoryIllustration from "../../../../../assets/img/setCategory.inline.svg";
import { StaticImage } from "gatsby-plugin-image";

const entryContent = {
    title: "See your money like never before.",
    subtitle: "Financial clarity is everything! Budget on Kuda will show you details of your spending so you can make better money decisions.",
    illustration: <StaticImage src={`../../../../../assets/img/kuda-new-budget.png`} placeholder="blurred" alt="kuda Microfinance Bank Savings, Spend, Budgeting." loading="eager"/>

}

const kudaTopFeatures = [{
    icon: <Card />,
    text: "Dive into your transactions to see where your money is going."
}, {
    icon: <BudgetIcon />,
    text: "Create a budget to track your spending and saving."
}, {
    icon: <Money />,
    text: "Get a cystal-clear visual analysis of your finances."
}]

const payNothing = {
    payTitle: "Keep your money at eye level.",
    subtitle: "Save it, spend it, send it. It’s up to you. Whatever you choose to do with your money, we’ll make sure it’s done better and free of charge. We take responsibility for that.",
    url: "",
    name: "",
    payIllustration: <SetBudgetIllustration />
}

const debit = {
    payTitle: "Categorize your spending.",
    subtitle: "You can’t avoid spending. Know exactly what you have spent with transaction details and our multiple transaction categories.",
    url: "/en-ng/cards/",
    name: "Learn More About Cards",
    payIllustration: <SetCategoryIllustration />
}

const payBills = {
    payTitle: "Pro Tip: Use with Spend + Save",
    subtitle: "Spend+Save is always a good way to reserve some money even when you’ve set a budget. Tap the Spend+Save button on your dashboard and set a small percentage to save every time money leaves your account.",
    url: "",
    name: "",
    payIllustration: <SpendSaveIllustration />
}

const Budget = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <EntryFold title={entryContent.title} subtitle={entryContent.subtitle} illustration={entryContent.illustration} fallback={<BudgetHeadingFallback className="blur isImage" />} isImage={true} />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight title={payNothing.payTitle} subtitle={payNothing.subtitle} illustration={payNothing.payIllustration} name={payNothing.name} url={payNothing.url} />
            <ImageTextLeft title={debit.payTitle} subtitle={debit.subtitle} illustration={debit.payIllustration} name={debit.name} url={debit.url} />
            <ImageTextRight title={payBills.payTitle} subtitle={payBills.subtitle} illustration={payBills.payIllustration} name={payBills.name} url={payBills.url} />
            <CTA />
        </Fragment>
    )
}

export default Budget;
